const BaseUrl="";
//const BaseUrl = "https://localhost:44381/api";
//const BaseUrl ="https://distributorconnect-dev.basf.com/api";
//const BaseUrl = "https://distributorconnect-qa.basf.com/api";

export const environment = {
  production: false,
  apiUrl: '',
  //Cups Request's API's
  getMasterData: `/api/RequestSubmission/BindCupsDropdowndata`,
  getGridData: `/api/RequestSubmission/GetRequestSubmissionGrid`,
  getallGridData: `/api/RequestSubmission/GetAllRequestSubmissionGrid`,
  updateUserGridCols:`/api/RequestSubmission/UpdateUserGridCols`,
  getProductDetails: `/api/RequestSubmission/GetProductDetails`,
  submitEVEMRequest: `/api/RequestSubmission/CreateRequestSubmission`,
  submitPMRequest: `/api/RequestSubmission/CreatePMRequest`,
  getRequestDetails: `/api/RequestSubmission/GetRequestDetailsById`,
  getApprovalHistory: `/api/RequestSubmission/GetApprovalHistoryDetails`,
  getSoldToList: `/api/RequestSubmission/GetSoldToList`,
  activateCup: `/api/RequestSubmission/SubmitActivePriceSupport`,
  getDelegationApprovers: `/api/RequestSubmission/GetDelegationApprovers`,
  createDelegation: `/api/RequestSubmission/CreateDeligationRequest`,
  createEmCupDelegation: `/api/RequestSubmission/CreateEmCupDeligation`,
  massChange: `/api/RequestSubmission/MassEMPMUpdate`,
  updateComments: `/api/RequestSubmission/UpdateInternalComments`,
  getProductPricing: `/api/RequestSubmission/GetProductPricing`,
  sendEmailReminder: `/api/RequestSubmission/SentRemainderMail`,
  getEMPMHistory: `/api/RequestSubmission/GETEMPMHistory`,
  updateExpiryDate: `/api/RequestSubmission/UpdateExpiryDate`,
  getSalesSupportDistributors: `/api/RequestSubmission/GetSalesSupportDistributors`,
  deleteSupport: `/api/RequestSubmission/DeleteSupport`,
  
  //Distributor's Module
  getDistributorDetails: `/api/Distributor/GetDistributorDetails`,
  getDistributorUserDetails: `/api/Distributor/GetDistributorUserDetails`,
  getDistributorDropDownData: `/api/Distributor/GetDropdownDataforDistributor`,
  submitDistributorDetails: `/api/Distributor/InsertOrUpdateDistributor`,
  saveDistributorUsertoThreeIamCall: `/api/Distributor/InsertDistributorUserToThreeIam`,
  
  getDistributorGridData: `/api/Distributor/GetGridDistributorDetails`,

  //Admin Users Module
  createUser: `/api/Users/CreateUserRequest`,
  getUsers: `/api/Users/GetUsers`,
  getAllUsers: `/api/Users/GetAllUsers`,
  getAdminConfigMaster: `/api/Admin/GetAdminConfigMaster`,
  getApprovalConfig: `/api/Admin/getApprovalConfig`,
  saveAdminConfig: `/api/Admin/SaveAdminConfig`,
  getSalesSupportUsers: `/api/RequestSubmission/GetSalesSupport`,
  getBUInternalUsers: `/api/Users/GetAllUserDetailsBasedOnBU`,
  getBUUsers: `/api/Users/GetBASFUsersByBU`,
  getProductDetailsById: `/api/Admin/getProductDetailsbyId`,
  deleteUser: `/api/Users/DeleteUserRequest`,
  UploadEndMarketSaleReport: `api/Admin/UploadEndMarketSaleReport`,
  getEndMarketSaleReport: `api/Admin/GetEndMarketSaleReport`,


  postSalesSupUsers: `/api/Admin/SaveSalesSupportPricingAdminData`,
  postSalescommercialUsers: `/api/Admin/SaveSalesCommericalMangerMapping`,
  getAllSaleSupportPricingAdmin: `/api/Admin/GetAllSalesSupportPricingAdminData`,
  getAllSalesCommericalManger: `/api/Admin/GetAllSalesCommericalMangerMappingData`,

  getAllProductsyBu: `/api/Admin/getAllProductsyBu`,
  UpdateProductMangerMapping: `/api/Admin/UpdateProductMangerMapping`,
  DeleteProductMangerMapping: `/api/Admin/DeleteProductMangerMapping`,
  postEVProductDetails: `/api/Admin/UpdateEVProductDetails`,
  postChangeProductStatus: `/api/Admin/ChangeProductStatus`,
  postChangeProductManager: `api/Admin/ChangeProductManager`,
  getUsersByEmailId: `/api/Users/GetUserDetailsByemailId`,
  createCustomer: `/api/Admin/CreateCustomer`,
  createCompetitor: `/api/Admin/createCompetitor`,

  //Admin - Account Details
  getAllAccountDetails:'/api/AccountDetails/GetAccountDetails',
  deleteAccountDetails:'/api/AccountDetails/DeleteAccountDetails',
  updateAccountDetails:'/api/AccountDetails/UpdateAccountDetails',
  insertAccountDetails:'/api/AccountDetails/InsertAccountDetails',
  getAccountData:'/api/AccountDetails/GetAllAccountsData',
  
  //Rebate API's
  createRebate: `/api/Rebate/CreateRebateTemplate`,
  getRebateFormData: `/api/Rebate/GetRebateTemplatFormData`,
  downloadRebatesEMEV: `/api/Rebate/DownloadRebatesEMEV`,
  
  getRebateDetails: `/api/Rebate/GetRebateTemplateDetails`,
  getRebatedGridData: `/api/Rebate/GetRebateTemplateGridData`,
  uploadRebateTemplate: `/api/Rebate/UploadRebateTemplate`,
  downloadRebates: `/api/Rebate/DownloadRebates`,
  uploadRebateExport: `/api/Rebate/UploadRebateExport`,

  //WorkFlow Status
  updateWorkFlow: `/api/WorkFlow/UpdateWorkFlowStatus`,
  updateRCWrokFlow: `/api/WorkFlow/UpdateRejectWithCounterOffer`,
  getWorkFlowDetails: `/api/WorkFlow/GetWorkFlowDetails`,
  getCounterOfferInfo: `/api/WorkFlow/GetRejectWithCounterOfferDetails`,
  updateDistributorResponse: `/api/WorkFlow/UpdateDistributorResponse`,
  resendSupportConfirmationEmail: `/api/WorkFlow/ResendSupportConfirmationEmail`,

  //Saml API's
  getSamlURL: `/api/GetSamlURL`,
  getsamlResponse: `/api/Getsaml`,

  //Upload excel
  uploadExcelTemplate: `/api/Upload/UploadExcelTemplate`,
  MassChangeUploadExcel: `/api/Upload/MassChangeUploadExcel`
};

